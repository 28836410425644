/**
 * For each slider
 * This file includes JS code for handling all sliders in the site
 */

import Flickity from "flickity";
require("flickity-imagesloaded");
require("flickity-as-nav-for");

class Slider {
  constructor(sliderSelector) {
    this.sliderBlock = sliderSelector;

    if (this.sliderBlock && this.sliderBlock.length) {
      this.initSliders();
    }
  }

  initSliders() {
    const that = this;
    this.sliderBlock.forEach((slider) => {
      const sliderSlide = slider.querySelectorAll(".slider__slide");

      if (sliderSlide && sliderSlide.length > 1) {
        const sliderConfig = that.checkSliderConfigs(slider);
        this.initSlider(slider, sliderConfig);
      }

      // Reveal animation though if its not a slider
      if (sliderSlide.length === 1) {
        slider.classList.add("slider--none");

        sliderSlide.forEach((slideItem) =>
          slideItem.classList.add("reveal-animation"),
        );
      }
    });
  }

  checkSliderConfigs(sliderElement) {
    let config;

    if (sliderElement) {
      const dots = sliderElement.getAttribute("data-slider-onlydots");
      const autoplay = sliderElement.getAttribute("data-slider-autoplay");
      const asNavForSlider = sliderElement.parentElement.querySelector(
        ".slider--controls",
      )
        ? sliderElement.getAttribute("data-slider-list-as-nav-for")
        : false;
      const adaptiveHeight = sliderElement.getAttribute("data-slider-adaptive-height");

      config = {
        dots: dots,
        autoplay: autoplay,
        asNavForMegaCTAList: asNavForSlider,
        adaptiveHeight: adaptiveHeight && adaptiveHeight === "false" ? false : true,
      };

      return config;
    }

    config = {
      dots: false,
    };

    return config;
  }

  initSlider(slider, config) {
    let that = this;

    if (slider) {
      const carousel = new Flickity(slider, {
        cellAlign: "left",
        imagesLoaded: true,
        groupCells: true,
        contain: true,
        adaptiveHeight: config.adaptiveHeight,
        pageDots: config.dots ? config.dots : false,
        prevNextButtons: config.dots ? false : true,
        autoPlay: config.autoplay == "undefined" ? false : config.autoplay,
        pauseAutoPlayOnHover: true,
        arrowShape:
          "M48.539 97.126L0 47.2 48.589 0l3.485 3.586L7.073 47.302 52.124 93.64z",
        on: {
          ready: function() {
            // Check if the slider contains prev next button
            const disabledSliderButtons = slider.querySelectorAll(".flickity-button[disabled]");
            if (!config.dots && disabledSliderButtons && disabledSliderButtons.length == 2) {
              disabledSliderButtons.forEach((button) => button.style.display = "none");
              slider.classList.add('slider-disabled');
            }
          }
        }
      });

      const carouselControlEl = slider.parentElement.querySelector(
        ".slider--controls",
      );

      if (config.asNavForMegaCTAList && carouselControlEl) {
        const carouselControl = new Flickity(carouselControlEl, {
          asNavFor: slider,
          pageDots: false,
          cellAlign: "left",
          draggable: false,
        });

        carouselControlEl.querySelectorAll("a").forEach((link) => {
          if (link) {
            link.onclick = (e) => {
              e.preventDefault();
            };
          }
        });
      }
    }
  }
}

export default Slider;
