const List = require("list.js");

class DocumentationFilter {
  constructor() {
    this.documentationList = document.querySelectorAll(
      ".js-documentation-list--filter",
    );

    if (this.documentationList) {
      this.initFilterList();
    }
  }

  initFilterList() {
    this.documentationList.forEach((listDocumentation) => {
      const documentationSearchField = listDocumentation.querySelector(
        ".js-documentation-search-field",
      );

      if (documentationSearchField) {
        documentationSearchField.onfocus = () => {
          const siteHeaderHeight = document
            .querySelector("header.site-header")
            .getBoundingClientRect().height;
          const searchBarTopOffset =
            documentationSearchField.getBoundingClientRect().y -
            siteHeaderHeight -
            40;

          if (searchBarTopOffset) {
            window.scroll({ top: searchBarTopOffset, behavior: "smooth" });
          }
        };
      }

      const filterOption = {
        listClass: "documentation-block__list--filter",
        valueNames: ["js-documentation-title"],
        searchClass: "js-documentation-search-field",
      };
      const initFilter = new List(listDocumentation, filterOption);
    });
  }
}

export default new DocumentationFilter();
