const Cookies = require("js-cookie");

class Cookiebar {
  constructor() {
    const siteID = "2321";
    const cookieTitle = "CHYRON";
    const cookiebar = document.getElementById("cookiebar");
    const cookieAcceptButton = cookiebar.querySelector(
      "button.cookie-accept-button",
    );
    this.cookieName = encodeURIComponent("user_aggrement_cookiebar_" + siteID); // where siteID is constant of current blog id added in head
    this.cookieValue = cookieTitle ? encodeURIComponent(cookieTitle) : 1; // where siteID is constant of current blog id added in head
    this.cookiebar = cookiebar;
    this.cookieAcceptButton = cookieAcceptButton;

    if (cookiebar && cookieAcceptButton && siteID && cookieTitle) {
      this.cookieCheckups();
    }
  }

  cookieCheckups() {
    let that = this;

    if (Cookies.get(that.cookieName)) {
    } else {
      that.cookieAcceptButton.onclick = () => {
        Cookies.set(that.cookieName, this.cookieValue, {
          expires: 365,
          path: "/",
        });

        if (Cookies.get(that.cookieName)) {
          that.cookiebar.classList.remove("is-visible");
        }
      };
      that.cookiebar.classList.add("is-visible");
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  new Cookiebar();
});
