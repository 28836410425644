import "./Menu.js";
import "./Header.js";
import "./Modal.js";
import "./Searchbar.js";
import "./ContentLoader.js";
import "./SmoothLink.js";
import "./lazyload.js";

// Filters
import "./DocumentationFilter.js";
import "./FreelanceDatabaseFilter.js";
import "./JobFilter.js";

// Cookiebar
import "./Cookiebar.js";

// Load slider as soon as the DOM is loaded
import "./products-slider.js";
import Slider from "./sliders/Slider.js";
document.addEventListener("DOMContentLoaded", () => {
  new Slider(document.querySelectorAll(".slider"));
});
