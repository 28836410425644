const List = require("list.js");

class FreelanceDatabaseFilter {
  constructor() {
    this.freelanceDBFilter = document.querySelectorAll(".filter-block");

    if (this.freelanceDBFilter) {
      this.initDBFilter();
    }
  }

  initDBFilter() {
    this.freelanceDBFilter.forEach((dbFilter) => {
      const filter = dbFilter.querySelector(".js-filter--wrapper");
      const filterLocationField = dbFilter.querySelector(
        ".js-filter--location"
      );
      const filterResetLink = dbFilter.querySelector(".js-filter--all");
      const filterCountryField = dbFilter.querySelector(".js-filter--country");
      const filterProductField = dbFilter.querySelector(
        ".js-filter--product-expertise"
      );
      const filterListItems = dbFilter.querySelectorAll(
        ".js-filter-list--items"
      );
      const filterErrMsg = dbFilter.querySelector(".js-filter-error-message");
      let instace = [];

      const itemOptions = {
        listClass: "filter-block__posts-list",
        valueNames: ["address", "product"],
      };

      filterListItems.forEach((target, i) => {
        instace.push(new List(target, itemOptions));
      });

      if (filterProductField) {
        filterProductField.onchange = () => {
          const filterProductFieldValue = filterProductField.value;

          // Reset other fields
          filterLocationField ? (filterLocationField.value = "") : false;
          filterCountryField ? (filterCountryField.value = "all") : false;

          instace.forEach((list) => {
            list.filter((item) => {
              if (filterProductFieldValue === "all") {
                return true;
              } else if (item.values().product == filterProductFieldValue) {
                return true;
              } else {
                return false;
              }
            });
          });
        };
      }

      const countryFilterOption = {
        listClass: "js-filter--list",
        valueNames: [{ data: ["country"] }],
      };

      const countryList = new List(filter, countryFilterOption);

      if (filterCountryField) {
        filterCountryField.onchange = () => {
          // Reset other fields
          filterLocationField ? (filterLocationField.value = "") : false;
          filterProductField ? (filterProductField.value = "all") : false;

          const country = filterCountryField.value;
          countryList.filter((item) => {
            if (country == "all") {
              return true;
            } else if (item.values().country == country) {
              return true;
            } else {
              return false;
            }
          });
        };
      }

      if (filterResetLink) {
        filterResetLink.onclick = (e) => {
          e.preventDefault();
          // Reset Filter values
          filterLocationField ? (filterLocationField.value = "") : false;
          filterCountryField ? (filterCountryField.value = "all") : false;
          filterProductField ? (filterProductField.value = "all") : false;

          // Reset
          countryList.filter();
          instace.forEach((list) => {
            list.filter();
          });
        };
      }

      // When list is updated
      instace.forEach((list) => {
        list.on("updated", () => {
          let hiddenFilterItems = 0;
          filterListItems.forEach((filterItem) => {
            const filteredData = filterItem.querySelectorAll(".filter-block__posts-list--item");

            if (filteredData && filteredData.length > 1) {
              filterItem.style.display = "block";
            } else if (filteredData && filteredData.length > 0) {
              filterItem.style.display = "none";
              hiddenFilterItems++;
            }
          });

          // Check if every filterListItems is hidden
          if (filterListItems.length == hiddenFilterItems) {
            filterErrMsg.style.display = 'block';
          } else {
            filterErrMsg.style.display = 'none';
          }
        });
      });
    });
  }
}

export default new FreelanceDatabaseFilter();
