/**
 * Scripts for loading and filtering news/blogs
 * For Blog/News Archive Page
 */
import lazyResources from "./lazyload.js";
const fetch = require("node-fetch");

class ContentLoader {
  constructor() {
    this.contentListBlock = document.querySelector(
      ".post-listing--content-list",
    );
    this.contentLoadMoreButton = document.querySelector(
      ".js-button-content-loadmore",
    );

    if (this.contentListBlock) {
      this.contentListBlockFilter = this.contentListBlock.querySelector(
        ".filter--form",
      );
      this.contentListBlockList = this.contentListBlock.querySelector(
        ".js-content-list",
      );
      this.isBeingLoaded;
      this.paged = 1;
      this.maxNumPages = this.contentListBlock.getAttribute(
        "data-posts-max-pages",
      );
      this.searchField = this.contentListBlockFilter.querySelector(
        ".js-input-search",
      );
      this.contentTypeField = this.contentListBlockFilter.querySelector(
        ".js-select-post-type",
      );
      this.categoryField = this.contentListBlockFilter.querySelector(
        ".js-posts-category-filter",
      );

      // Get Values
      this.searchFieldValue = this.searchField.value;
      this.contentTypeFieldValue = this.contentTypeField.value;
      this.categoryFieldValue = this.categoryField.value;

      if (this.contentListBlock && this.contentListBlockFilter) {
        this.initFilter();
        this.initLoadMore();
      }
    }
  }

  initFilter() {
    const that = this;
    if (that.contentListBlockFilter) {
      // Init functions
      that.searchField.addEventListener("keyup", (event) => {
        that.searchFieldValue = event.target.value;
        setTimeout(() => {
          that.fetchPosts();
        }, 10);
      });

      that.contentTypeField.onchange = (event) => {
        if (event.target.value !== "post") {
          that.categoryField.parentElement.classList.add(
            "form__field--disabled",
          );
        } else {
          that.categoryField.parentElement.classList.remove(
            "form__field--disabled",
          );
        }
        that.contentTypeFieldValue = event.target.value;
        setTimeout(() => {
          that.fetchPosts();
        }, 10);
      };

      that.categoryField.onchange = (event) => {
        that.categoryFieldValue = event.target.value;
        setTimeout(() => {
          that.fetchPosts();
        }, 10);
      };
    }
  }

  initLoadMore() {
    const that = this;

    that.contentLoadMoreButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (that.maxNumPages > that.paged && !that.isBeingLoaded) {
        that.fetchPosts("loadmore");
      }
    });
  }

  fetchPosts(fetchType) {
    const that = this;
    if (!that.isBeingLoaded) {
      const loadingClass =
        fetchType == "loadmore"
          ? "is-loading--loadmore-posts"
          : "is-loading--filtered-posts";
      that.isBeingLoaded = true;
      that.paged = fetchType !== "loadmore" ? 1 : that.paged;
      that.contentListBlock.classList.add(loadingClass);
      that.contentLoadMoreButton.setAttribute("disabled", "disabled");

      // Prepare request data for AJAX request
      const requestData = new FormData();
      requestData.append(
        "nonce",
        document.querySelector("body").getAttribute("data-nonce"), // Nonce to verify request
      );

      requestData.append(
        "action",
        fetchType == "loadmore" ? "content_loadmore" : "content_filter",
      );
      requestData.append(
        "search_value",
        that.searchFieldValue == " " ? false : that.searchFieldValue,
      );
      requestData.append("cpt", that.contentTypeFieldValue);
      if (that.contentTypeFieldValue == "post") {
        requestData.append(
          "category",
          that.categoryFieldValue ? that.categoryFieldValue : false,
        );
      }
      requestData.append("paged", that.paged);

      // Fetch
      fetch(ajax_url, {
        method: "POST",
        body: requestData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.response) {
            that.isBeingLoaded = false;
            that.paged = fetchType == "loadmore" ? data.paged : 1;
            that.maxNumPages = data.max_num_pages;

            that.contentListBlock.setAttribute(
              "data-posts-max-pages",
              this.maxNumPages,
            );

            if (fetchType !== "loadmore") {
              that.contentListBlockList.innerHTML = data.response;
            } else {
              that.contentListBlockList.innerHTML += data.response;
            }

            that.contentListBlock.classList.remove(loadingClass);

            // load images
            lazyResources.update();
          } else {
            that.isBeingLoaded = false;
            that.contentListBlock.classList.remove(loadingClass);
          }

          // If paged is equal to maxNumPages, hide load more button
          if (parseInt(data.paged) >= parseInt(data.max_num_pages)) {
            that.contentLoadMoreButton.parentElement.style.display = "none";
          } else {
            that.contentLoadMoreButton.parentElement.style.display = "flex";
          }

          // Enable load more button
          that.contentLoadMoreButton.removeAttribute("disabled");
        })
        .catch((error) => {
          that.isBeingLoaded = false;
          that.contentListBlockList.classList.remove(loadingClass);
          that.contentLoadMoreButton.removeAttribute("disabled");
          console.warn(error);
        });
    }
  }
}

const contentLoaderFunc = new ContentLoader();

export default contentLoaderFunc;
