import Flickity from "flickity";

// Slider
const productSliderElements = document.querySelectorAll(".js-products-slider");

if (productSliderElements) {
  productSliderElements.forEach(element => {
    return new Flickity(element, {
      cellAlign: "left",
      pageDots: true,
      prevNextButtons: true,
      contain: true,
      groupCells: true,
      arrowShape: "M48.539 97.126L0 47.2 48.589 0l3.485 3.586L7.073 47.302 52.124 93.64z",
    });
  });
}
