const Masonry = require("masonry-layout");
import { slideDown, slideUp } from "./helpers/plainSlide.js";

/**
 * For Header Menu
 */
class Navigation {
  constructor() {
    this.body = document.querySelector("body");
    this.menuOpener = document.querySelector(".js-menu-opener");
    this.menuElement = document.querySelector("#main-menu");
    this.menuList = document.querySelector(".menu__list");
    this.menuItem = document.querySelectorAll(".menu__item").length;
    this.menuWithItems = document.querySelectorAll(".menu-item-has-children");
    this.topMenuItemDropdownMenus = document.querySelectorAll(
      ".menu__item--top-has-child"
    );

    if (this.menuOpener) {
      this.menuOpener.addEventListener("click", () => {
        this.toggleNav();
      });
    }

    if (this.menuWithItems) {
      this.menuWithItems.forEach((item) => {
        const dropdownOpener = item.querySelector(".js-dropdown-menu-opener");
        const dropdownMenu = dropdownOpener
          ? dropdownOpener.nextSibling
          : false;

        if (dropdownOpener && dropdownMenu) {
          dropdownOpener.addEventListener("click", () => {
            dropdownMenu.classList.toggle("js-dropdown-menu-active");

            if (dropdownMenu.style.display === "block") {
              slideUp(dropdownMenu);
            } else {
              slideDown(dropdownMenu);
            }

            // Close other active dropdown menus
            this.menuWithItems.forEach((otherMenuItem) => {
              if (otherMenuItem !== item) {
                const otherDropdownOpener = otherMenuItem.querySelector(
                  ".js-dropdown-menu-opener"
                );
                const otherDropdownMenu = otherDropdownOpener
                  ? otherDropdownOpener.nextSibling
                  : false;

                if (otherDropdownMenu) {
                  otherDropdownMenu.classList.remove("js-dropdown-menu-active");
                  if (otherDropdownMenu.style.display === "block") {
                    slideUp(otherDropdownMenu);
                  }
                }
              }
            });
          });
        }
      });
    }

    // Initializes masonryMenu
    this.masonryMenu();
  }

  toggleNav() {
    if (this.body.classList.contains("js-menu-active")) {
      this.body.classList.remove("js-menu-active");
      this.menuOpener.setAttribute("aria-label", "Menu");
    } else {
      this.body.classList.add("js-menu-active");
      this.menuOpener.setAttribute("aria-label", "Close menu");
    }
  }

  masonryMenu() {
    let windowWidth = window.innerWidth;
    const menuMegaDropdowns = document.querySelectorAll(".menu__dropdown--mega-list");
    if (menuMegaDropdowns && (windowWidth > 1024 || windowWidth == 1024)) {
      menuMegaDropdowns.forEach((menuMegaDropdown) => {
        const masonryMenu = new Masonry(menuMegaDropdown, {
          columnWidth: ".grid-sizer",
          gutter: 20,
          horizontalOrder: true,
          itemSelector: ".menu__dropdown--mega-menu-item",
          percentPosition: true,
        });

        window.addEventListener("resize", () => {
          if (masonryMenu && window.innerWidth <= 1024) {
            masonryMenu.destroy();
          }
        });
      });
    }
  }
}

export default new Navigation();
