const fetch = require("node-fetch");

class JobFilter {
  constructor() {
    this.jobFilter = document.querySelector(".js-job-filter-form");

    if (this.jobFilter) {
      this.initJobFilter();
    }
  }

  initJobFilter() {
    const that = this;
    const filterButton = that.jobFilter.querySelector(".js-job-filter-button");

    // Form on submit
    that.jobFilter.onsubmit = (e) => {
      e.preventDefault();
      const filterData = new FormData(that.jobFilter);
      that.fetchJobs(filterData, filterButton);
    };
  }

  fetchJobs(filteredData, filterButton) {
    const that = this;
    if (filteredData && filterButton) {
      filterButton.classList.add("is-loading");

      fetch(ajax_url, {
        method: "POST",
        body: filteredData,
      })
        .then((response) => response.json())
        .then((data) => that.handleData(data, filterButton))
        .catch((err) => that.handleError(err, filterButton));
    }
  }

  handleData(data, filterButton) {
    const that = this;
    const jobList = document.querySelector(".js-job-list--filter");

    if (data && filterButton && jobList) {
      jobList.innerHTML = data.response;
      jobList.setAttribute(data.max);
    } else {
      that.handleError(false, filterButton);
    }
  }

  handleError(error, filterButton) {
    if (filterButton) {
      filterButton.classList.remove("is-loading");
    }
  }

  clearJobFilters() {
    if (this.jobFilter) {
      const inputFields = this.jobFilter.querySelectorAll("input");
      if (inputFields && inputFields.length) {
        inputFields.forEach((input) => {
          if (input.checked) {
            input.checked = false;
          }
        });

        this.jobSearchField.value = '';

        document.querySelector(
          ".js-job-list--filter",
        ).innerHTML = this.defaultJobList;
      }
    }
  }
}

export default new JobFilter();
