/**
 * Modal.js
 */
import Slider from "./sliders/Slider";
import lazyResources from "./lazyload.js";

class Modal {
  constructor() {
    this.modalLinks = document.querySelectorAll("[data-modal], a");
    this.siteWrapper = document.getElementById("app");
    this.modalOverlayOpacity = 0.7;
    this.exitOverlayOnEscape = true;
    this.activeModal = document.querySelector('.Modal__show');

    if (this.modalLinks && this.modalLinks.length) {
      this.initModal();
    }

    this.activeModal ? this.handleSelfContentModal(this.activeModal) : false;
  }

  initModal() {
    const that = this;
    that.modalLinks.forEach((modalLink, i) => {
      if (modalLink) {
        modalLink.onclick = (e) => {
          if (
            modalLink.hasAttribute("data-modal") ||
            (modalLink.getAttribute("href") &&
              modalLink.getAttribute("href").includes("#modal"))
          ) {
            e.preventDefault();
            that.modalLinkTarget(modalLink);
          }
        };
      }
    });
  }

  modalLinkTarget(modalLink) {
    const that = this;
    const modalType = modalLink.getAttribute("data-modal-type");
    const modalLinkData = modalLink.getAttribute("data-modal");

    const modalContentElement =
      modalType !== "oembedded"
        ? document.querySelector("." + modalLinkData)
        : false;
    const modelContent = modalContentElement
      ? modalContentElement.innerHTML
      : false;

    if (
      modalLinkData &&
      that.isValidURL(modalLinkData) &&
      modalType == "oembedded"
    ) {
      that.handleOembeddedModal(modalLinkData, modalType, modalLink);
    } else if (modalType == "content" && modelContent) {
      that.handleNormalModal(modelContent, modalType);
    } else {
      const modalLinkURL = modalLink.getAttribute("href");
      const modalBlock = modalLinkURL
        ? document.querySelector(modalLinkURL)
        : false;
      modalBlock ? that.handleSelfContentModal(modalBlock) : false;
    }
  }

  handleSelfContentModal(modalBlock) {
    if (modalBlock && modalBlock.classList.contains("Modal")) {
      const Modal = modalBlock;

      // Activate Modal
      Modal.classList.add("Modal__show");
      document.querySelector("body").classList.add("js-modal--active");

      // Deactivate Modal
      const modalOverlay = Modal.querySelector(".Modal__overlay");
      const modalCloser = Modal.querySelector(".js-modal-close");
      if (modalOverlay) {
        modalOverlay.onclick = (e) => {
          Modal.classList.remove("Modal__show");
          document.querySelector("body").classList.remove("js-modal--active");
        };
      }

      if (modalCloser) {
        modalCloser.onclick = (e) => {
          e.preventDefault();
          Modal.classList.remove("Modal__show");
          document.querySelector("body").classList.remove("js-modal--active");
        };
      }

      if (
        document.querySelector("body").classList.contains("js-modal--active")
      ) {
        document.onkeyup = (e) => {
          if (e.key === "Escape") {
            Modal.classList.remove("Modal__show");
            document.querySelector("body").classList.remove("js-modal--active");
          }
        };
      }
    }
  }

  handleNormalModal(modelContent, modalType) {
    const that = this;
    let activeModalElement = document.querySelector(".js-modal-content");

    if (!activeModalElement) {
      activeModalElement = document.createElement("div");
      activeModalElement.className = "js-modal-content";
    }

    const content = that.getModalHTML(modelContent, modalType);
    activeModalElement.innerHTML = content;
    that.activeModal = activeModalElement;

    if (that.activeModal) {
      that.siteWrapper.appendChild(that.activeModal);

      setTimeout(() => {
        that.activateModal();
      }, 200);
    }
  }

  handleOembeddedModal(modalLinkData, modalType, modalLink) {
    const that = this;
    let activeModalElement = document.querySelector(".js-modal-content");
    // const oembeddedModalThumbnail = modalLink.previousElementSibling?.hasAttribute('src') ? modalLink.previousElementSibling.getAttribute("src") : false;
    // Check if modallink previous sibling element is image
    const oembeddedModalThumbnail =
      modalLink.previousElementSibling &&
      modalLink.previousElementSibling.tagName === "IMG" && 
      modalLink.previousElementSibling.hasAttribute("src") ? modalLink.previousElementSibling.getAttribute("src") : false;

    if (!activeModalElement) {
      activeModalElement = document.createElement("div");
      activeModalElement.className = "js-modal-content";
    }

    const content = that.getModalHTML(modalLinkData, modalType, oembeddedModalThumbnail);
    activeModalElement.innerHTML = content;
    that.activeModal = activeModalElement;

    if (that.activeModal) {
      that.siteWrapper.appendChild(that.activeModal);
      setTimeout(() => {
        that.activateModal();
      }, 80);
    }
  }

  activateModal() {
    let that = this;
    document.querySelector("body").classList.add("js-modal--active");
    this.activeModal.querySelector(".Modal").classList.add("Modal__show");
    const closeBtn = this.activeModal.querySelector(".js-modal-close");
    const modalOverlay = this.activeModal.querySelector(".Modal__overlay");

    // If the modal content contains slider
    that.activeModal.querySelectorAll(".slider")
      ? new Slider(that.activeModal.querySelectorAll(".slider"))
      : false;

    // If the modal data needs to be lazy loaded
    // load images
    lazyResources.update();

    modalOverlay.onclick = () => {
      that.deactivateModal();
    };

    if (this.activeModal && this.exitOverlayOnEscape) {
      document.onkeyup = (e) => {
        if (
          e.key === "Escape" &&
          this.activeModal &&
          document.querySelector("body").classList.contains("js-modal--active")
        ) {
          that.deactivateModal();
        } else {
          return false;
        }
      };
    }

    if (closeBtn) {
      closeBtn.onclick = (e) => {
        e.preventDefault();
        that.deactivateModal();
      };
    }
  }

  deactivateModal() {
    if (this.activeModal) {
      document.querySelector(".Modal").classList.remove("Modal__show");

      setTimeout(() => {
        document.querySelector("body").classList.remove("js-modal--active");
        this.activeModal.innerHTML = "";
      }, 200);
    }
  }

  getModalHTML(content, modalType, thumbnail) {
    let html;
    const that = this;

    if (content && modalType) {
      html =
        "<div class='Modal'><span class='Modal__overlay overlay' style='opacity: " +
        this.modalOverlayOpacity +
        ";'></span><div class='Modal__wrap Modal__wrap--" +
        modalType +
        "'>";
      html +=
        "<a href='#' class='Modal__close js-modal-close'><svg viewbox='0 0 17.4 16.5' fill='currentColor' aria-label='Close'><use xlink:href='#close'></use></svg></a>";
      html += "<div class='Modal__content'>";

      if (modalType == "oembedded") {
        html +=
          modalType == "oembedded"
            ? "<div class='Modal__iframe--wrapper' style='background-image: url(" + thumbnail +"); background-position: 50% 50%; background-size: cover;'><iframe src='" +
              content +
              "' class='Modal__iframe' allowfullscreen autoplay></iframe><svg viewBox='0 0 16 16' width='16' height='16' class='Modal__iframe--loader' aria-hidden='true'><use href='#loadingIcon'></use></svg></div>"
            : false;
      } else {
        html += "<div class='Modal__content--wrapper'>" + content + "</div>";
      }
      html += "</div></div></div>";
      return html;
    }
  }

  // Check if string is URL or not
  isValidURL(str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return !!pattern.test(str);
  }
}

export default new Modal();
