// Makes Header Sticky after scrolling down
const makeHeaderStickyToTop = () => {
  const header = document.querySelector("header.site-header");
  const body = document.querySelector("body");

  if (body.classList.contains("has-top-banner")) {
    if (window.scrollY > 40) {
      header.classList.add("site-header--non-transparent");
      body.classList.add("page-scrolled");
    } else {
      header.classList.remove("site-header--non-transparent");
      body.classList.remove("page-scrolled");
    }
  }
};

// Set headerHeight as top spacing
const topHeaderSpacing = () => {
  let headerHeight = document.querySelector("header.site-header");

  if (
    headerHeight &&
    headerHeight.getBoundingClientRect().height &&
    !document.querySelector("body").classList.contains("has-top-banner")
  ) {
    document.querySelector("body").style.marginTop =
      headerHeight.getBoundingClientRect().height + "px";
  }
};


// After the DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  document.querySelector("body").classList.remove("no-transitions");
  document.querySelector("body").classList.add("dom-loaded");
  makeHeaderStickyToTop();
  topHeaderSpacing();
});

// When window is resized
window.addEventListener("resize", () => {
  topHeaderSpacing();
});

// When window is scrolled
document.addEventListener("scroll", makeHeaderStickyToTop);
